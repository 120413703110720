input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gm-style .gm-style-iw-c {
  padding: 15px !important;
}

.feedback-detail table thead {
  display: none;
}

.feedback-detail table tbody {
  border: none;
}

/* .terminal tr th {
  position: sticky;
  top: 0;
  background-color: rgb(17 24 39);
  padding: 20px;
} */

.terminal tr th,
.terminal tr td {
  text-align: left;
  padding: 16px 20px 0 20px;
}
.terminal tr td:first-child {
  text-align: left;
  padding: 0 20px 0 20px;
}

.terminal tr td {
  white-space: nowrap;
}

.hide-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hide-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.scrollbar-terminal::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 8px;
}

/* Track */
.scrollbar-terminal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollbar-terminal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar-terminal::-webkit-scrollbar-thumb:hover {
  background: #555;
}